<template>
  <div class="exchangeCon">
    <!-- 积分兑换 -->
    <div class="title">
      <h5 class="blod">{{ $t("vip.redeem_points") }}</h5>
      <p>{{ $t("vip.redeem_points_detail") }}</p>
    </div>
    <div class="exlist">
      <div class="exl-item" v-for="(item, index) in list" :key="index">
        <div class="timg">
          <q-img
            :src="
              item.id == 7
                ? voucher
                : item.id == 8
                ? free
                : item.id == 9
                ? vip
                : ''
            "
            :ratio="30 / 33"
          />
        </div>
        <div class="ex_b">
          <h6>{{ $i18n.locale == "en" ? item.titleen : item.title }}</h6>
          <p class="intro">{{ $i18n.locale == "en" ? item.introduceen : item.introduce }}</p>
          <div class="blackBtn">
            <q-btn
              dense
              type="submit"
              class="full-width"
              color="primary"
              :label="$t('vip.see_option')"
              @click="goList(item)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPrize } from '@/api/my'
export default {
  data () {
    return {
      list: [],
      voucher: require('@/assets/images/voucher-banner.jpg'),
      vip: require('@/assets/images/vip-card-banner.jpg'),
      free: require('@/assets/images/free-delivery-banner.jpg')
    }
  },
  methods: {
    async getExchange () {
      const res = await getPrize({
        action: 'VIPdiscountlist_h'
      })
      this.list = res.VIPdiscounts
    },
    goList (item) {
      this.$router.push(
        '/my/exchangeList?type=' +
          item.id +
          '&title=' +
          item.title +
          '&titleen=' +
          item.titleen +
          '&des=' +
          item.introduce +
          '&desen=' +
          item.introduceen
      )
    }
  },
  mounted () {
    this.getExchange()
  }
}
</script>

<style lang="scss" scoped>
.exchangeCon {
  .title {
    text-align: center;
    h5 {
      font-size: 14px;
      margin-bottom: 5px;
      margin-top: 0;
    }
    p {
      font-size: 14px;
    }
  }
  .exlist {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    .exl-item {
      width: 32%;
      overflow: hidden;
      border: 1px solid #a8a8a8;
      border-radius: 5px;
      transition: all 0.3s;
      display: flex;
      flex-direction: column;
      &:hover {
        box-shadow: 0 2px 12px #ccc;
        transform: translateY(-3px);
      }
      .timg {
        width: 100%;
        text-align: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .ex_b {
        text-align: center;
        margin-top: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        h6 {
          font-size: 14px;
          margin: 0px;
          font-weight: bold;
        }
        .intro{
          font-size: 14px;
          padding: 0 15px;
          flex: 1;
          margin-bottom: 0;
        }
        .blackBtn {
          width: 90%;
          margin: 15px auto;
        }
      }
    }
  }
}
</style>
